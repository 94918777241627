import { useAuth0 } from '@auth0/auth0-react';
import { Check, X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useSearch } from '@tanstack/react-router';

import { useEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import {
  useAccountLinkingListener,
  useAuth0Claims,
  useLoginToLinkableAccount,
} from 'src/api/queries/user';
import {
  Button,
  Link,
  NotificationBox,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { TestId } from 'src/testids';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';
import { useHasLinkedAccounts } from '../utils/useHasLinkedAccounts';

export function VerifySamlPage() {
  const accountLinkingListener = useAccountLinkingListener({
    returnUrl: URLS.ENTERPRISE_CONFIRM_SETUP,
  });
  const loginToLinkableAccount = useLoginToLinkableAccount();
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const linkedStatus = useHasLinkedAccounts();
  const { user } = useAuth0();
  const claims = useAuth0Claims();
  const search = useSearch({
    from: '/_auth/_layout/enterprise/enroll/verify-saml',
  });

  const isLoggedInToSSO = !!claims.data?.org_id;

  if (accountLinkingListener.isPending || linkedStatus.isPending) {
    return <Spinner />;
  }

  if (linkedStatus.isError) {
    return <SomethingWentWrong />;
  }

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content
        className="max-w-ll"
        data-cy={TestId.PageEnterpriseEnrollmentVerifySAML}
      >
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Verify SAML
            </Typography>
          </div>

          <div className="flex justify-between gap-24 border-y py-16">
            <Typography variant="body-md-regular">
              Owner: {user?.email ?? 'No email'}
            </Typography>

            {isLoggedInToSSO ? (
              <div className="flex items-center gap-8 text-pen-blue">
                <Check size={24} />
                <span>Verified</span>
              </div>
            ) : (
              <div className="flex items-center gap-8 text-neutral-dark-1">
                <X size={24} />
                <span>Not verified</span>
              </div>
            )}
          </div>

          {isLoggedInToSSO ? (
            <Typography variant="body-md-regular">
              Your SAML setup has been verified.
            </Typography>
          ) : (
            <div className="flex flex-col gap-16">
              <Typography variant="body-md-regular">
                To verify the SAML setup:
              </Typography>

              <ol className="list-inside list-decimal">
                <li>Add your email address to your identity provider (IdP)</li>
                <li>
                  Log in with SSO to verify and migrate to an enterprise account
                </li>
              </ol>

              <div className="w-full bg-neutral-light-3 px-12 py-8">
                <Typography variant="body-sm-regular">
                  By verifying, you accept migration of account and the
                  following:
                </Typography>

                <ul className="list-disc pl-24">
                  <li>
                    <Typography variant="body-sm-regular">
                      Transfer of control and ownership of your reMarkable
                      content to the Business.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body-sm-regular">
                      Termination of applicable reMarkable Accounts Terms and
                      Conditions we have with you.
                    </Typography>
                  </li>
                </ul>

                <Link inline to="#" className="text-14">
                  Read more
                </Link>
              </div>
            </div>
          )}

          {search.accountLinkingError && (
            <NotificationBox variant="warning" title="Make sure to log out">
              We see that the verification failed because of mismatching
              accounts. You might have an active SSO session somewhere else.
              Please make sure you are logged out of the IdP before verifying.
            </NotificationBox>
          )}

          <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
            <div className="flex flex-1 ls:justify-center lm:justify-start">
              <CancelSetupModal
                trigger={
                  <Button
                    variant="tertiary-neutral"
                    className="w-full ls:w-fit"
                  >
                    Cancel
                  </Button>
                }
              />
            </div>

            <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
              <Button
                as="a"
                variant="secondary"
                to={URLS.ENTERPRISE_SETUP_SAML}
                className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
              >
                Previous
              </Button>
              {isLoggedInToSSO ? (
                <Button
                  as="a"
                  to={URLS.ENTERPRISE_CONFIRM_SETUP}
                  variant="primary"
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                  disabled={
                    loginToLinkableAccount.isPending || linkedStatus.isPending
                  }
                  loading={
                    loginToLinkableAccount.isPending || linkedStatus.isPending
                  }
                  onClick={() => {
                    if (typeof enrollmentStatus.data?.connectionId !== 'string')
                      return;

                    if (!linkedStatus.isSuccess) return;

                    loginToLinkableAccount.mutate({
                      connection: enrollmentStatus.data.connectionId,
                      startLinking: !linkedStatus.data.isLinked,
                    });
                  }}
                >
                  {linkedStatus.data.isLinked
                    ? 'Login to SSO'
                    : 'Link account to SSO'}
                </Button>
              )}
            </div>
          </div>

          <NotificationBox error={loginToLinkableAccount.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
