import * as Sentry from '@sentry/react';
import { createFileRoute, redirect } from '@tanstack/react-router';
import jwtDecode from 'jwt-decode';

import { RmClaims } from 'src/api/queries/user';
import { LogCategory } from 'src/utils/logger.categories';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/')({
  async beforeLoad({ context }) {
    const auth = await context.auth;
    const accessToken = jwtDecode<RmClaims>(
      await auth.getAccessTokenSilently()
    );

    if (!auth.user) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'User not found, redirecting to login',
      });
      // this is already handled by _auth, but TS wants us to handle it here too.
      throw redirect({
        to: '/login',
        statusCode: 401,
      });
    }

    if (userHelpers.hasActiveEnterpriseEnrollment(auth.user)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'User has active enterprise enrollment, redirecting to enroll',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/enroll',
        replace: true,
      });
    }

    if (!userHelpers.isOrganizationMember(auth.user)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message:
          'User is not an organization member, redirecting to /enterprise/enroll',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/enroll',
        replace: true,
      });
    }

    if (userHelpers.hasPermissionReadMembers(accessToken)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message:
          'User has permission to read members, redirecting to /enterprise/members',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/members',
        replace: true,
      });
    }

    Sentry.addBreadcrumb({
      category: LogCategory.Auth,
      message: 'Redirecting to /enterprise/plan',
    });

    throw redirect({
      from: '/enterprise',
      to: '/enterprise/plan',
    });
  },
});
