import { Desktop, DeviceMobileCamera } from '@phosphor-icons/react';
import { Tag, Typography } from '@remarkable/ark-web';
import {
  ArrowRight,
  ArrowUpRight,
  DownloadSimple,
  Layout,
} from 'phosphor-react';
import QRCode from 'react-qr-code';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { getMobileLink } from 'src/apps/device/utils/utils';
import methodsHomepage from 'src/assets/img/methods-homepage.webp';
import { LinkExternal } from 'src/components/LinkExternal';
import { defaultConfig } from 'src/config';
import { URLS } from 'src/utils/urls/urls';

import { Button } from './Button';
import { DesktopAppLink } from './DesktopAppLink';
import { Divider } from './Divider';
import { Sheet } from './Sheet';

interface DownloadAppsProps {
  to: string;
}

export const DownloadApps = ({ to }: DownloadAppsProps) => {
  const mobileAppRedirectUrl = new URL(
    URLS.DOWNLOAD_MOBILE_APP_REDIRECT,
    window.location.href
  );

  return (
    <Sheet className="mt-32 flex max-w-lxl flex-col gap-32 text-left lm:mt-64">
      <div className="flex flex-col gap-24">
        <Typography variant="heading-md">Take your thinking further</Typography>
        <Typography variant="body-md-regular">
          Kick-start your next task with new templates from reMarkable Methods,
          and work conveniently across your devices with our apps. Time to get
          even more out of your workday with Connect.
        </Typography>
      </div>
      <Divider />
      <div className="flex flex-row gap-64">
        <div className="flex basis-full flex-col gap-16 lxl:basis-[65%]">
          <Tag variant="success-light" size="large">
            NEW TEMPLATES
          </Tag>
          <div className="flex flex-row flex-nowrap items-center gap-16">
            <Layout size={32} />
            <Typography variant="heading-subtle-2xl">
              reMarkable Methods
            </Typography>
          </div>
          <Typography variant="body-sm-regular">
            Lead meetings, brainstorm, and plan your day with a new collection
            of exclusive templates.
          </Typography>
          <LinkExternal
            to={defaultConfig.MethodsUrl}
            componentLocation={ComponentLocations.SETUP.DOWNLOAD_APPS}
            className="border-b border-transparent font-medium text-pen-blue no-underline hover:border-pen-blue hover:no-underline"
          >
            <span className="inline-flex items-center gap-8">
              Go to methods.remarkable.com
              <ArrowUpRight className="!h-16 !w-16 !transform-none" size={16} />
            </span>
          </LinkExternal>
        </div>
        <div className="hidden basis-[45%] flex-col justify-center lxl:flex">
          <img
            src={methodsHomepage}
            alt="A screen capture of the Methods homepage"
            className="mx-auto aspect-video w-full min-w-[272px] max-w-full lxl:w-auto"
          />
        </div>
      </div>

      <Divider />
      <div className="flex flex-col-reverse lm:flex-row">
        <div className="flex-col lm:flex lm:w-1/2 lm:border-r lm:pr-32">
          <div className="flex flex-row flex-nowrap items-center gap-16">
            <Desktop size={32} />
            <Typography variant="heading-subtle-2xl"> Desktop app</Typography>
          </div>

          <Typography className="mt-16" variant="body-sm-regular">
            Create, transfer, and organize on your PC or Mac with the desktop
            app.
          </Typography>
          <div className="mt-24 flex flex-wrap gap-x-12 gap-y-8">
            <DesktopAppLink platform="macos" />
            <DesktopAppLink platform="windows" />
          </div>
        </div>
        <Divider className="my-32 lm:hidden" />
        <div className="flex flex-col lm:w-1/2 lm:pl-32">
          <div className="mt-0 flex flex-row flex-nowrap items-center gap-16">
            <DeviceMobileCamera size={32} />
            <Typography variant="heading-subtle-2xl">Mobile app</Typography>
          </div>

          <div className="mt-16 flex w-full flex-row justify-between gap-24 lm:mt-32">
            <div>
              <Typography variant="body-sm-regular">
                Be ready when inspiration strikes. Capture thoughts on your
                phone, and develop them further on your paper tablet.
              </Typography>
              <Typography
                variant="body-sm-regular"
                className="mt-16 hidden lm:block"
              >
                Scan the QR code to download
              </Typography>
              <Button
                as="a"
                to={getMobileLink()}
                variant="secondary"
                size="medium"
                className="mt-24 w-full lm:hidden"
                analytics={{
                  location: ComponentLocations.SETUP.DOWNLOAD_APPS,
                  text: 'Download',
                  action: 'download mobile app',
                }}
              >
                <DownloadSimple size={24} />
                <span>Download</span>
              </Button>
            </div>

            <QRCode
              size={90}
              value={mobileAppRedirectUrl.href}
              className="hidden w-fit shrink-0 lm:block"
            />
          </div>
        </div>
      </div>
      <div className="mt-32 w-full">
        <Button
          as="a"
          to={to}
          variant="primary"
          className="float-right w-full lm:w-fit"
          analytics={{
            location: ComponentLocations.SETUP.DOWNLOAD_APPS,
            text: 'Next',
            action: 'go to next step',
          }}
        >
          <span>Next</span>
          <ArrowRight />
        </Button>
      </div>
    </Sheet>
  );
};
