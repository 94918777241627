import { Typography } from '@remarkable/ark-web';
import { Check } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Button, CardContent, Link, Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { cn } from 'src/utils/classNamesHelper';
import { URLS } from 'src/utils/urls/urls';

export const SetupComplete = ({
  secondDevice = false,
  className,
}: {
  secondDevice: boolean;
  className?: string;
}) => (
  <AppPage.Content className="max-w-ll">
    <Sheet className={cn('mb-32', className)}>
      <CardContent className="flex flex-col gap-16">
        <Typography variant="heading-lg">You&apos;re all set</Typography>

        <Typography variant="body-md-regular">
          {!secondDevice ? (
            <span>
              Setup completed successfully. At{' '}
              <Link
                inline
                to={URLS.HOME}
                className="text-pen-blue no-underline"
                trackingLocation={ComponentLocations.SETUP.SETUP_COMPLETE}
              >
                {' '}
                my.remarkable.com
              </Link>{' '}
              you can manage your reMarkable universe and discover features to
              improve your workflow.
            </span>
          ) : (
            `Your content will now sync to the cloud automatically, and across
          devices, as long as you’re connected to Wi-Fi.`
          )}
        </Typography>
      </CardContent>
      <Button
        className="ml-auto"
        variant="tertiary"
        as="a"
        to={URLS.HOME}
        analytics={{
          location: ComponentLocations.SETUP.SETUP_COMPLETE,
          text: 'Close',
          action: 'close setup',
        }}
      >
        <span>Close</span>
        <Check />
      </Button>
    </Sheet>
  </AppPage.Content>
);
