import * as Sentry from '@sentry/react';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { LogCategory } from 'src/utils/logger.categories';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/_with-org')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth;

    if (!auth.user) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: '_with-org: User not found, redirecting to login',
      });

      throw redirect({ to: '/login' });
    }

    if (userHelpers.isLoggedInToOrganization(auth.user)) {
      return;
    }

    if (userHelpers.hasActiveEnterpriseEnrollment(auth.user)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'User has active enterprise enrollment, redirecting to enroll',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/enroll',
        replace: true,
      });
    }

    if (userHelpers.getOrganizationId(auth.user)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message:
          '_with-org: User is not logged in to an organization, redirecting to login',
      });
      throw redirect({
        to: '/login',
        search: {
          login_hint: userHelpers.getEmail(auth.user),
          organization: userHelpers.getOrganizationId(auth.user),
          redirect: location.href,
        },
      });
    }

    Sentry.addBreadcrumb({
      category: LogCategory.Auth,
      message:
        '_with-org: User is not an organization member, redirecting to /',
    });

    throw redirect({
      statusCode: 403,
      to: '/',
      search: {
        access_denied: 'Only users with an organization can access this page.',
      },
    });
  },
});
