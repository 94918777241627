import loglevel from 'loglevel';

import { Pageview } from 'src/ampli';
import { AnalyticsCoupons } from 'src/analytics';
import { Subscription } from 'src/api/endpoints/storeApi.types';
import { subscriptionGetPaymentInterval } from 'src/utils/subscriptionGetPaymentInterval';
import { subscriptionGetStatus } from 'src/utils/subscriptionGetStatus';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

import { tracker } from './tracker';
import { UserPropertiesSubscription } from './trackingTypes';

export const checkoutTypeToCoupon = (type?: string) => {
  if (!type) {
    return AnalyticsCoupons.UNKNOWN;
  }
  switch (type) {
    case 'retailOffer':
      return AnalyticsCoupons.RETAIL_OFFER;
    case 'subscriptionOffer':
      return AnalyticsCoupons.SUBSCRIPTION_OFFER;
    case 'store':
      return AnalyticsCoupons.NONE;
    default:
      return AnalyticsCoupons.UNKNOWN;
  }
};

export const MONTHLY_CONNECT_PRICE_IN_USD_FOR_TRACKING = 2.99;

export const getSubscriptionForTracking = (
  subscription?: Subscription
): Omit<UserPropertiesSubscription, 'subscription_role'> => {
  if (!subscription) {
    return {
      subscription_type: 'none',
      subscription_status: 'none',
      subscription_payment_interval: 'none',
    };
  }

  if (subscriptionHelpers.isFreeConnect(subscription)) {
    return {
      subscription_type: 'free connect',
      subscription_status: 'active',
      subscription_payment_interval: 'none',
    };
  }

  const paymentInterval = subscriptionGetPaymentInterval(subscription);
  const isConnectForBusiness =
    subscriptionHelpers.isManagementTypeSeats(subscription);
  const subscriptionStatus = subscriptionGetStatus(subscription);

  if (!isConnectForBusiness) {
    return {
      subscription_type: 'connect',
      subscription_status: subscriptionStatus || 'none',
      subscription_payment_interval: paymentInterval || 'none',
    };
  }

  if (subscriptionHelpers.isManagementTypeSeats(subscription)) {
    return {
      subscription_type: 'connect with seats',
      subscription_status: subscriptionStatus || 'none',
      subscription_payment_interval: paymentInterval || 'none',
    };
  }

  return {
    subscription_type: 'none',
    subscription_status: 'none',
    subscription_payment_interval: 'none',
  };
};

function trackPageview(path: string, action?: string) {
  const actionLabel = action ? ` (${action})` : '';

  loglevel.debug(`[analytics/pageview]: ${path}${actionLabel}`);

  tracker.trackEvent(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    new Pageview({
      url: path,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  );
}

export const analyticsHelpers = {
  trackPageview,
};
