export type UserPropertiesSubscription = {
  subscription_type: 'free connect' | 'connect' | 'connect with seats' | 'none';
  subscription_status:
    | 'active'
    | 'trialing'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'incomplete'
    | 'none';
  subscription_payment_interval: 'monthly' | 'annually' | 'none';
  subscription_role: 'owner' | 'member' | 'none';
};

export const ComponentLocations = {
  FRONT_PAGE: {
    DASHBOARD: 'front page dashboard',
    HERO: 'front page hero',
    LEARNING_CONTENT: 'front page learning content',
    METHODS_CARD: 'front page methods card',
    SETUP_PROCESS: 'front page setup process',
  },
  MENU: {
    HEADER_HAMBURGER_MENU: 'header hamburger menu',
    SIDE_MENU: 'side menu',
  },
  FOOTER: {
    FAQ: 'footer FAQ',
  },
  HEADER: {
    MAIN: 'header',
    USER_MENU: 'header user menu',
  },
  CHECKOUT: {
    CONNECT_CHECKOUT: 'connect checkout',
    CONFIRMATION: 'connect checkout confirmation',
    LANDING: 'connect checkout landing',
    CONNECT_OFFER_LANDING_MAIN: 'connect offer landing main',
    CONNECT_OFFER_LANDING_BOTTOM: 'connect offer landing bottom',
  },
  SETUP: {
    DOWNLOAD_APPS: 'setup download apps',
    SETUP_COMPLETE: 'setup complete',
  },
  CONNECT: {
    PROMO: 'connect promotion page',
    SUBSCRIBE_HERO: 'subscribe to connect hero',
    MANAGE_CONNECT_NOTIFICATION: 'manage connect notification',
    NO_ACTIVE_SUBSCRIPTION_CARD: 'no active subscription card',
  },
  MANAGE_CONNECT: {
    MAIN: 'manage connect',
    PAYMENT_INTERVAL_MODAL: 'manage connect payment interval modal',
    SUBSCRIPTION_PAGE: 'manage connect subscription page',
    PAYMENT_PAGE: 'manage connect payment page',
    MEMBERS_PAGE: 'manage connect members page',
  },
  CONNECT_WITH_SEATS: {
    INVITATION_MEMBER_PAGE: 'connect with seats invitation member page',
  },
  SURVEY: {
    ANSWER: 'survey answer',
  },
  DEVICE: {
    READ_ON_REMARKABLE: 'device app read on remarkable',
    DEVICE: 'device app paper tablet',
    DESKTOP: 'device app desktop app',
    MOBILE: 'device app mobile app',
  },
  ACCOUNT: {
    BILLING_INFO: 'account billing info',
    CONTACT_DETAILS: 'account contact details',
    SECURITY_CARD: 'account security card',
    DELETION: 'account deletion card',
    MIGRATE_LEGACY: 'account migrate from legacy page',
    MIGRATE_SAML: 'account migrate from saml page',
    MIGRATE_CONFIRM: 'account migrate confirm page',
  },
  SUBSCRIPTION_CARD: {
    MAIN: 'subscription card',
  },
  SUBSCRIPTION: {
    MEMBERS_PAGE: 'subscription members page',
    PLAN_PAGE: 'subscription plan page',
    PAYMENT_PAGE: 'subscription payment page',
  },
  NEWS_MODULE: {
    MOBILE_AND_DESKTOP_NOTETAKING: 'news module mobile and desktop notetaking',
    INTRODUCING_TYPE_FOLIO: 'news module introducing type folio',
  },
  FAQ: {
    MAIN: 'FAQ',
  },
  EXPLORE: {
    REMARKABLE_APPLICATIONS: 'explore remarkable applications',
    REMARKABLE_INTEGRATIONS: 'explore remarkable integrations',
    REMARKABLE_EXTENSIONS: 'explore remarkable extensions',
  },
  NOTIFICATIONS: {
    FRONT_PAGE: 'front page notification',
    ACCOUNT_PAGE: 'account page notification',
    UPDATE_PAYMENT_CARD: 'update payment card notification',
  },
  ERROR_MODULES: {
    SOMETHING_WENT_WRONG: 'something went wrong module',
  },
  OPEN_PAGES: {
    DOWNLOAD_MOBILE_APP: 'open download mobile app page',
  },
  FLEET: {
    FLEET_MEMBERS_TABLE: 'fleet members table',
  },
  MRF_MODAL: {
    MRF_MODAL: 'mrf modal',
    MRF_STEP_1: 'mrf step 1',
    MRF_STEP_2: 'mrf step 2',
    MRF_STEP_3: 'mrf step 3',
    MRF_STEP_4: 'mrf step 4',
    MRF_STEP_5: 'mrf step 5',
  },
} as const;

export const CHECKOUT_STEPS = {
  CHECKOUT_PROCESS_STARTED: 'Successfully started checkout process',
  BILLING_INFORMATION_VALIDATED: 'Billing information validated',
} as const;
